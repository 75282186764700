<template>
  <div class="home">
    <div class="top">
      <img src="/img/logo.png" alt="" height="50" class="logo">
      <el-link class="loadout" @click="loadout()">退出登录</el-link>
    </div>
    
    <div class="table-main">
      <!-- 创建核销码 按钮 -->
      <el-button class="add-btn" type="primary" @click="addCode">+ 创建核销码</el-button>
      <!-- 列表 -->
      <div style="width: 100%;height: 500px;">
        <el-table :data="codeListData" stripe style="width: 100%">
          <el-table-column prop="code" label="太阳码"/>
          <el-table-column label="类型">
            <template v-slot="scope">
              <span>{{ typeOptions[scope.row.type]}}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否被使用">
            <template v-slot="scope">
              <span :style="{color: (scope.row.used?'#4DC412':'#ccc')}">{{ scope.row.used?'已使用':'未使用' }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="orderId" label="订单号"/>
          <el-table-column prop="remark" label="备注"/>
          <el-table-column prop="createDate" label="创建时间"/>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-link type="primary" @click="showCode(scope.row)">查看太阳码</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination background layout="prev, pager, next" :total="params.total" :pageSize="params.pageSize"
        :pageNum="params.pageNum" @current-change="handleCurrentChange"/>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="创建核销码" v-model="visible" width="700px" :close-on-click-modal="false" :before-close="closeDialog">
      <div style="height: 450px;">
        <el-form :model="formData" label-width="auto" style="max-width: 600px">
          <el-form-item label="类型">
            <el-radio-group v-model="formData.type" :disabled="lockEdit">
              <el-radio value="102" size="large">月卡核销码</el-radio>
              <el-radio value="103" size="large">年卡核销码</el-radio>
            </el-radio-group>
          </el-form-item>
          
          <el-form-item label="订单号">
            <el-input v-model="formData.orderId" :disabled="lockEdit"/>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="formData.remark" :disabled="lockEdit"/>
          </el-form-item>
        </el-form>
        <el-button class="create-btn" type="primary" @click="createCode" :disabled="lockEdit">创建</el-button>
        <div style="display: flex; justify-content: center;  height: 250px;" v-if="showAddCodeVisible">
          <div style="display: flex; flex-direction: column;">
            <el-image class="code-img" :src="showCodeData.codeUrl" fit="cover"/>
            <div style="margin-top: 20px;text-align: center;">{{ codeTip(showCodeData) }}</div>
          </div>
        </div>
      </div>
      
    </el-dialog>
    <el-dialog title="太阳码" v-model="showCodeVisible" width="300px" :close-on-click-modal="false" :before-close="closeCodeDialog">
      <div style="display: flex; justify-content: center;  height: 250px;">
        <div style="display: flex; flex-direction: column;">
          <el-image class="code-img" :src="showCodeData.codeUrl" fit="cover"/>
          <div style="margin-top: 20px;text-align: center;">{{ codeTip(showCodeData) }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      typeOptions: {
        102: '月卡核销码',
        103: '年卡核销码'
      },
      visible: false,
      formData: {
        orderId: null,
        remark: null,
        type: "102", // 0月卡，1是年卡
      },
      codeListData: [],
      lockEdit: true,
      showCodeVisible: false,
      showCodeData: {},
      showAddCodeVisible: false,
      params: {
        total: 0,
        pageSize: 10,
        pageNum: 1
      },
    }
  },
  props: {
  },
  created() {
    this.getList()
  },
  methods: {
    toLogin() {
      this.$router.push({path: '/login'})
    },
    getList() {
      this.$http.get('/api/oc/rqCodeExList?pageSize=' + this.params.pageSize + '&pageNum=' + this.params.pageNum).then(res => {
        console.log(res)
        let { code, data, msg } = res.data
        if (code == 200) {
          this.codeListData = data.list
          this.params.total = data.total
        } else {
          console.log(msg)
        }
      }).catch(err => {
				console.log(err)
			})
    },
    handleCurrentChange(val) {
      this.params.pageNum = val
      this.getList()
    },
    addCode() {
      this.lockEdit = false
      this.visible = true
      this.showCodeData = {}
    },
    createCode() {
      console.log(this.formData)
      this.$http.post('/api/oc/createProductActionRQCode', this.formData).then(res => {
        console.log(res)
        let { code, data, msg } = res.data
        if (code == 200) {
          this.showCodeData.codeUrl = data.url
          this.showCodeData.code = data.code
          this.showCodeData.orderId = this.formData.orderId
          this.showAddCodeVisible = true
          this.getList()
        } else {
          console.log(msg)
        }
        this.lockEdit = true
      }).catch(err => {
				console.log(err)
			})
    },
    resetFormData() {
      this.formData = {
        orderId: null,
        remark: null,
        type: "102"
      }
    },
    closeDialog() {
      this.visible = false
      this.resetFormData()
      this.lockEdit = true 
      this.showAddCodeVisible = false
      this.showCodeData = {}
    },
    showCode(row) {
      this.showCodeData = row
      this.showCodeVisible = true
    },
    closeCodeDialog() {
      this.showCodeVisible = false
      this.codeUrl = null
    },
    codeTip(codeData) {
      let text = ''
      if (codeData.orderId != null && codeData != '') {
        text = '订单Id：' + codeData.orderId
      } else {
        text = 'code:' + codeData.code
      }

      return text
    },
    loadout() {
      this.$utils.LocalStorage._removeStorage("token")
      this.$router.push('/login')
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(to bottom, #FC5E2F, #F74931);
  padding: 0 20px;
}
.logo{
  margin: 5px;
}
.add-btn {
  margin: 20px 0px;
}
.table-main{
  margin: 0 auto;
  width: 1400px;
}
.code-img{
  width: 200px; 
  height: 200px;
}
.loadout{
  color: #fff;
}
.el-link:hover{
  color: #FCC9BC;
}
</style>
