/**
 * localStorage的增加，删除，读取
 * 存储字符串，对象均可
 */
class LocalStorage {
  static _saveStorage = (key, value) => {
    let val = typeof (value) == 'string' ? value : JSON.stringify(value)
    localStorage.setItem(key, val);
  }
  static _getStorage = (key) => {
    let val = localStorage.getItem(key)
    let checkType = (value) => {
      try {
        JSON.parse(value)
        return true
      } catch (err) {
        return false
      }
    }
    if (val != null) {
      return checkType(val) ? JSON.parse(val) : val
    }
  }
  static _removeStorage = (key) => {
    localStorage.removeItem(key)
  }
}

class StringUtils{
  static isBlank(str) {
    return str == undefined || str == null || str == ''
  }
  static isNotBlank(str) {
    return !this.isBlank(str)
  }
}


export const utils = {StringUtils, LocalStorage}
