import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/Home.vue'
import LoginPage from '@/views/LoginPage.vue'

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // 登录
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  
];

const router = createRouter({ 
 history: createWebHistory(process.env.BASE_URL),
 routes
})

// 到出路由
export default router