import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { utils } from './utils/utils'
import VueAxios from 'vue-axios'
import axios from "axios"
import { md5 } from 'js-md5'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './theme.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


// vue3.0
const app = createApp(App)


// 全局挂载
app.config.globalProperties.$utils = utils // 工具类
app.config.globalProperties.$utils.md5 = md5 // 工具类


// request拦截器  可对请求进行相应的处理
axios.interceptors.request.use(
    function (config) {
        const token = utils.LocalStorage._getStorage("token");
        if (token) {
            config.headers['token'] = token;
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)


// 响应拦截器
axios.interceptors.response.use(
    function (response) {
        switch (response.data.code) {
            case 601:
                console.log("token无效")
                router.push('/login')
                break;
            case 666:
                console.log("token无效")
                router.push('/login')
                break;
        }
        return response;
    },
    function (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    console.log("404")
                    break;
            }
        }
        return Promise.reject(error);
    }
);


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

app.use(router)
app.use(VueAxios, axios)
app.use(ElementPlus)
app.mount('#app')
