<template>
  <div class="login">
    <div class="login-card">
      <div class="title">E无忧运营中心登录</div>
      <div class="content">
        <el-input v-model="account" style="width: 100%; height: 40px; margin-bottom: 20px;">
          <template #prefix>
            <el-icon class="el-input__icon"><user/></el-icon>
          </template>
        </el-input>
        <el-input v-model="pwd" type="password" style="width: 100%; height: 40px; margin-bottom: 20px;">
          <template #prefix>
            <el-icon class="el-input__icon"><lock/></el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="clickLogin" style="width: 100%; height: 40px;">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogInPage', // 登录
  data() {
    return {
      account: '',
      pwd: '',
    }
  },
  methods: {
    clickLogin() {
      this.$http.post('/api/oc/login' ,{
        account: this.account,
        pwd: this.$utils.md5(this.pwd)
      }).then(res => {
        console.log(res)
        let { code, data, msg } = res.data
        if (code == 200) {
          this.$utils.LocalStorage._saveStorage("token", data)
          this.$router.push({path: '/'})
        } else {
          console.log(msg)
        }
      }).catch(err => {
				console.log(err)
			})
    }
  },
}
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;

}
.login-card{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.title{
  color: #FD5A31;
  font-weight: 700;
  font-style: normal;
  font-size: 42px;
  margin-bottom: 45px;
  text-align: center;
}
.content{
  width: 480px;
  background: #fff;
  padding: 50px;
  border-radius: 3px;
  box-sizing: border-box;
}
</style>
